import iframeResize from 'iframe-resizer/js/iframeResizer.min';
import MicroModal from "micromodal/dist/micromodal.es";
import ModalTemplate from "./templates/modal/modal.html?raw";
import SpinTemplate from "./templates/spin/spin.html?raw";
import "./templates/modal/modal.css";
import "./templates/spin/spin.css";

(function (window, document) {
  const scriptUrl = new URL(document.currentScript.src);
  const appParam = scriptUrl.searchParams.get('app');

  let widgets = [];
  let observer = null;

  function toggleLoading(parentNode, isLoading) {
    let loadingElement = parentNode.querySelector('#wally-loading');
    if (isLoading && !loadingElement) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = SpinTemplate.trim();
      loadingElement = tempDiv.firstChild;
      parentNode.appendChild(loadingElement);
      return;
    }

    if (!isLoading && loadingElement) {
      loadingElement.remove();
    }
  }

  function createIframe(src) {
    const iframe = document.createElement("iframe");
    iframe.width = "100%";
    iframe.style.border = "none";
    iframe.src = src;
    return iframe;
  }

  function createIframeForWidget(parentNode, widgetId) {
    toggleLoading(parentNode, true);
    const existingIframe = parentNode.querySelector('iframe');
    if (existingIframe) {
      parentNode.removeChild(existingIframe);
    }

    const iframe = createIframe(`${import.meta.env.VITE_APP_WIDGET_URL}/${widgetId}${appParam ? '/design' : ''}`);
    parentNode.appendChild(iframe);
    return iframe;
  }

  async function postInstall(widgetId) {
    try {
      await fetch(`${import.meta.env.VITE_APP_WIDGET_API_URL}/${widgetId}/install`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({widgetId: widgetId, website: window.location.href.split("?")[0]})
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function setupMutationObserver() {
    if (observer) {
      observer.disconnect();
    }

    observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-wally-widget') {
          init();
        }
      }
    });

    widgets.forEach(widget => observer.observe(widget, {attributes: true}));
  }

  function createMessageHandlers() {
    let wallyPreviewPhotoData = null;

    const messageHandlers = {
      "wally-widget-loaded": function (event, data) {
        if (!data) return;
        const {widgetId} = data;
        const parentNode = document.querySelector(`[data-wally-widget="${widgetId}"]`);
        toggleLoading(parentNode, false);
      },
      "wally-preview-photo-loaded": function () {
        if (!wallyPreviewPhotoData) return;

        const iframe = document.querySelector(`iframe[src*="${import.meta.env.VITE_APP_WIDGET_URL}/${wallyPreviewPhotoData?.widget?._id}/preview"]`);
        iframeResize({log: false, heightCalculationMethod: 'taggedElement'}, iframe);
        iframe.contentWindow.postMessage({type: "wally-preview-photo-set", data: {...wallyPreviewPhotoData}}, "*");
      },
      "wally-preview-photo": function (event, data) {
        if (!data) return;
        wallyPreviewPhotoData = data;

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = ModalTemplate.trim();

        const modal = tempDiv.firstChild;

        const iframe = createIframe(`${import.meta.env.VITE_APP_WIDGET_URL}/${data.widget._id}/preview`);

        const modalContainer = modal.querySelector('#wally-modal-container');
        modalContainer.style.backgroundColor = data.widget?.design?.itemBackgroundColor || "#fff";
        modalContainer.style.borderRadius = `${data.widget?.design?.borderRadius}px` || "8px";

        const modalContent = modal.querySelector('#wally-modal-content');
        modalContent.appendChild(iframe);

        document.body.appendChild(modal);

        // FYI: ESC not working with iframe
        // FYI: Arrows will work only if Carousel is focused (click on it first)
        MicroModal.show('wally-modal', {
          onClose: () => new Promise((resolve) => setTimeout(() => {
            modal.remove();
            resolve();
          }, 300)),
          closeTrigger: 'data-wallymodal-close',
          disableScroll: true,
        });
      },
    };

    if (!window.wallyIsEventListenerAdded) {
      window.addEventListener("message", function (event) {
        const {type, data} = event.data;

        if (messageHandlers[type]) {
          messageHandlers[type](event, data);
        }
      });
      window.wallyIsEventListenerAdded = true;
    }
  }

  function init() {
    widgets = document.querySelectorAll("[data-wally-widget]");

    widgets.forEach(parentNode => {
      if (!parentNode.dataset.wallyWidget) {
        return;
      }

      const widgetId = parentNode.dataset.wallyWidget;
      const iframe = createIframeForWidget(parentNode, widgetId);

      iframe.onload = function () {
        iframeResize({log: false, heightCalculationMethod: 'taggedElement'}, this);
        if (!appParam) {
          postInstall(widgetId);
        }
      };
    });

    setupMutationObserver();
    createMessageHandlers();
  }

  init();
})(window, document);
